<template>
  <responsive-ui-list
    :heading="heading"
    :headings="headings"
    :sort="[
      { prop: 'name', type: false },
      { prop: 'email', type: false },
    ]"
    :items="items"
  >
    <template v-slot:default="props">
      <ui-list-data
        class="list_data"
        :text="props.item.name"
      ></ui-list-data>
      <ui-list-data
        class="list_data"
        :text="props.item.email"
      ></ui-list-data>
      <ui-list-data
        class="list_data"
        v-if="courseEvent.field1"
        :text="props.item.field1"
      ></ui-list-data>
      <ui-list-data
        class="list_data"
        v-if="courseEvent.field2"
        :text="props.item.field2"
      ></ui-list-data>
      <ui-list-data
        small
        class="list_data"
      >
        <input
          class="attendance_checkbox"
          type="checkbox"
          v-bind:disabled="session.active === false"
          v-model="props.item.attendance"
          @change="$emit('saveSession')"
        />
      </ui-list-data>
    </template>
    <template v-slot:footer v-if="session.active === true">
      <tr style="vertical-align: top;">
        <ui-list-data class="list_data_add_field">
          <ui-form-field
            rules="required"
            :label="$t('fields.name')"
          >
            <el-input
              v-model="newParticipant.name"
              type="text"
              id="participantName"
              name="participantName"
            />
          </ui-form-field>
        </ui-list-data>
        <ui-list-data class="list_data_add_field">
          <ui-form-field
            rules="required|email"
            :label="$t('fields.email')"
          >
            <el-input
              v-model="newParticipant.email"
              type="text"
              id="participantEmail"
              name="participantEmail"
            />
          </ui-form-field>
        </ui-list-data>
        <ui-list-data
          class="list_data_add_field"
          v-if="courseEvent.field1"
        >
          <ui-form-field
            v-if="courseEvent.field1"
            rules="required"
            :label="courseEvent.field1"
          >
            <el-input
              v-model="newParticipant.field1"
              type="text"
              id="participantField1"
              name="participantField1"
            />
          </ui-form-field>
        </ui-list-data>
        <ui-list-data
          class="list_data_add_field"
          v-if="courseEvent.field2"
        >
          <ui-form-field
            v-if="courseEvent.field2"
            rules="required"
            :label="courseEvent.field2"
          >
            <el-input
              v-model="newParticipant.field2"
              type="text"
              id="participantField2"
              name="participantField2"
            />
          </ui-form-field>
        </ui-list-data>
        <ui-list-data class="list_data_add_field">
          <ui-form-field :label="$t('actions.add_participant')">
            <ui-button
              :class="isFormInputsComplete ? 'add_participant_button' : 'add_participant_button disabled'"
              :style="isFormInputsComplete ? { 'pointer-events': 'auto'} : { 'pointer-events': 'none'}"
              type="green"
              size="small"
              uppercase
              @click="createParticipant"
            >
              {{ $t("actions.add") }}
            </ui-button>
          </ui-form-field>
        </ui-list-data>
      </tr>
    </template>
  </responsive-ui-list>
</template>

<script>
import ResponsiveUiList from '../components/ResponsiveUiList'
import UiListData from "@/components/ui/UiListData";
import UiFormField from "@/components/ui/UiFormField";
import UiButton from "@/components/ui/UiButton";
import { createParticipant, isEmailRegisteredInGroup } from "../api";
import {capitalized} from "@/extras";
import { validate } from "vee-validate";

export default {
  props: {
    heading: {
      type: String,
    },
    participants: {
      type: Array,
      required: true,
    },
    courseEvent: {
      type: Object,
      required: true,
    },
    session: {
      type: Object,
      required: true,
    },
  },
  watch: {
    // Deep watcher for items
    items: {
      handler(_, __) {
        if (this.items == null) return;

        // Create uppercase locations to ensure correct sorting
        this.items.forEach((participant) => {
          participant.name = capitalized(participant.name);
        });
      },
      deep: true
    },

    // Deep watcher for newParticipant, checks validity of email
    newParticipant: {
      handler(newValue, _) {
        if (newValue.email) {
          // Email has value, validate it for later checks
          this.validateEmail(newValue.email);
        }
      },
      deep: true
    }
  },

  data() {
    return {
      items: [],
      newParticipant: {
        session: null,
        name: null,
        email: null,
        field1: null,
        field2: null,
        eventSession: {
          event: null,
        },
      },
      emailValid: false,
    };
  },

  components: {
    ResponsiveUiList,
    UiListData,
    UiFormField,
    UiButton,
  },
  computed: {
    isFormInputsComplete: function() {
      return this.allFieldsFilled();
    },
    headings: function () {
      let i = [this.$tc("fields.name", 1), this.$t("fields.email")];

      if (this.courseEvent.field1) {
        i = [...i, this.courseEvent.field1];
      }

      if (this.courseEvent.field2) {
        i = [...i, this.courseEvent.field2];
      }

      i = [...i, this.$t("misc.presence")];

      return i;
    },
  },

  methods: {
    async validateEmail(email) {
      if (!email) return false;

      const result = await validate(email, "required|email");
      this.emailValid = result.valid;
    },
    /** Checks if all fields are fille before adding new user, includes extra fields */
    allFieldsFilled() {
      const participant = this.newParticipant;
      let usingField1 = this.courseEvent && this.courseEvent.field1;
      let usingField2 = this.courseEvent && this.courseEvent.field2;

      let fields = participant.name && participant.email;
      
      // Validate extra fields too
      if (usingField1) {
        fields = fields && participant.field1;
      }
      if (usingField2) {
        fields = fields && participant.field2;
      }

      // If fields are not null / empty / undefined, check if email valid
      return fields ? this.emailValid : false;
    },
    async createParticipant() {
      if (!this.isFormInputsComplete) return;
      
      // If the last email entered is already registered, show error message instead
      const duplicateEmail = await isEmailRegisteredInGroup(this.session.id, this.newParticipant.email);
      if (duplicateEmail) {
        this.$message.error({ message: this.$t("validation.email_already_registered_in_session") });
        return;
      }

      this.loading = true;
      this.newParticipant.eventSession.event = this.courseEvent;
      this.newParticipant.session = this.session.id;
      this.newParticipant.attendance = true;

      try {
        const participant = await createParticipant(this.newParticipant);
        this.items = [...this.items, participant];
      } catch (err) {
        console.error(err);
      }

      this.resetParticipant();
      this.loading = false;
    },

    resetParticipant() {
      this.newParticipant.name = null;
      this.newParticipant.email = null;
      this.newParticipant.field1 = null;
      this.newParticipant.field2 = null;
    },
  },

  created() {
    this.items = this.participants;
  },
};
</script>

<style scoped>
.add_participant_button {
  width: 160px;
  height: 40px;
}
.attendance_checkbox {
  width: 20px;
  height: 20px;
}
.list_data {
  padding: 10px 24px;
}

.list_data_add_field {
  padding: 24px;
}
</style>
