<template>
    <div v-loading="loading">
      <auth-checker :accessRoles="['SuperAdmin', 'InstructorAdmin', 'Instructor']">
        <div v-if="courseEvent">
            <ui-card class="ui_card">
              <ui-header :heading="courseEvent.title">
                <template>
                  <h1 class="text-xl font-bold">{{ subheader }}</h1>
                </template>
              </ui-header>
              <RichTextField 
                v-model="courseEvent.description"
                :hideBorder="true"
                :showEditor="false">
              </RichTextField>
            </ui-card>
            <auth-checker :accessRoles="['SuperAdmin', 'InstructorAdmin', 'Instructor']">
                <participant-list
                    :courseEvent="courseEvent"
                    :heading="$t('models.participants')"
                    :participants="participants"
                    :session="session"
                    @saveSession="saveSession"
                />
            </auth-checker>
        </div>
        <div class="flex flex-row justify-end">
            <ui-button
                class="save_button"
                type="green"
                size="small"
                uppercase
                @click="save"
                >{{ $t('actions.save') }}
            </ui-button>
            <ui-link class="navigation_button" type="text" :route="{ name: 'courseevent-view' }" :params="{ id: this.$route.params.id }">
            {{
                `${$t('actions.return')}`
            }}
            </ui-link>
        </div>
      </auth-checker>
    </div>
</template>

<script>
import UiHeader from '@/components/ui/UiHeader';
import UiLink from '@/components/ui/UiLink';
import UiCard from '@/components/ui/UiCard';
import ParticipantList from '@/app/courseevents/components/CourseEventSessionParticipantList';
import AuthChecker from '../../auth/components/AuthChecker';
import UiButton from "@/components/ui/UiButton";
import RichTextField from "@/components/form/RichTextField";
import {getCourseEvent, saveParticipants} from '../api';
import {getLanguageResourceId} from "@/extras";

export default {
  data() {
    return {
      courseEvent: null,
      session: null,
      participants: [],
      newParticipant: {
        session: null,
        name: null,
        email: null,
        field1: null,
        field2: null,
        eventSession: {
            event: null
        },
      },
      loading: false,
    };
  },

  components: {
    UiHeader,
    UiLink,
    UiCard,
    ParticipantList,
    AuthChecker,
    RichTextField,
    UiButton
  },

  computed: {
    sessionDate: function() {
      return this.$d(new Date(this.session.startDateTime), 'fullShort', this.$i18n.locale === 'no' ? 'no' : 'en');
    },

    sessionStartTime: function() {
      return this.$d(new Date(this.session.startDateTime), 'time', this.$i18n.locale === 'no' ? 'no' : 'en');
    },

    sessionEndTime: function() {
      return this.$d(new Date(this.session.endDateTime), 'time', this.$i18n.locale === 'no' ? 'no' : 'en');
    },

    subheader: function() {
      return `${this.sessionDate} (${this.sessionStartTime} - ${this.sessionEndTime}) ${this.$t('language.language')}: ${this.$t(getLanguageResourceId(this.session.language))}`;
    }
  },

  methods: {
    async getCourseEvent() {
      const courseEvent = await getCourseEvent(this.$route.params.id);
      this.courseEvent = courseEvent;
      const session = courseEvent.sessions.find(x => x.id === this.$route.params.sessionId);
      this.session = session;
      this.participants = session.participants;
    },

    async save() {
      this.loading = true;
      const response = await this.saveSession()

      // Showing success status if API returned status-code 200
      if (response.status === 200) {
        this.$message({ message: this.$t("server_feedback.status_200_saved").toString(), type: "success" });
      }

      this.loading = false;

      // Waiting 1000ms so we can show toast before returning
      new Promise(_ => setTimeout(() => {
        this.$router.back();
      }, 1000));
    },

    async saveSession () {
        try {
            this.session.participants = this.participants;
            return await saveParticipants(this.session);
        } catch (err) {
            console.error(err);
        }
    },
  },

  async created() {
    this.loading = true;
    await this.getCourseEvent();

    // Update breadcrumbs with ids
    this.$store.commit('changeBreadcrumbs', [
      {name: "models.event", link: "/app/courseevent/" },
      {name: "models.single_session", link: `/app/courseevent/${this.courseEvent.id}/view`},
      {name: "models.participants", link: `/app/courseevent/${this.courseEvent.id}/session/${this.session.id}`}
    ]);

    this.loading = false;
  },

  mounted() {
    this.$store.commit('changeBreadcrumbs', [
      {name: "models.event", link: "/app/courseevent/" },
      {name: "models.single_session", link: ``},
      {name: "models.participants", link: ``}
    ]);
  },
}
</script>

<style scoped>
/** Fixing wrong margin on p, and showing lists */
:deep(.ProseMirror) p {
  margin-bottom: 0px;
}
:deep(.ProseMirror) ol {
  list-style-type: decimal;
  margin-left: 20px;
}
:deep(.ProseMirror) ul {
  list-style-type: disc;
  margin-left: 20px;
}
.ui_card {
    margin-bottom: 10px;
}
.navigation_button {
    margin-top: 5px;
    margin-left: 5px;
    background-color: white;
    border: solid 1px black;
    color: black;
}
.save_button {
    width: 120px;
    margin-top: 5px;
    margin-left: 5px;
}
</style>
